import React from "react";
import { Link } from "gatsby";
import makeUrl from "../../utils/url";
import { GatsbyImage } from "gatsby-plugin-image";

function Card({ title, type, image, imageUrl, noTitle, large, children }) {
  return (
    <div className="text-gray-900">
      <Link to={`/${makeUrl(title, type)}`}>
        <div
          className={`relative overflow-hidden pb-1 w-full transform hover:scale-95 duration-300`}
        >
          {imageUrl && (
            <img
              alt={title}
              src={imageUrl}
              className="absolute h-full w-full object-cover rounded-md"
            />
          )}
          {image && (
            <div className="absolute h-full w-full object-cover">
              <GatsbyImage
                image={image}
                className="h-full w-full rounded-md"
                alt={title}
              />
            </div>
          )}
        </div>
        {!noTitle && (
          <h2 className={`text-sm sm:text-${large ? "lg" : "base"} py-2`}>
            {title}
          </h2>
        )}
        {children}
      </Link>
    </div>
  );
}

export default Card;
