import React from "react";

function HeadingTop({ title, subtitle }) {
  return (
    <div>
      <div className="container-xl bg-red-50 bg-opacity-50 py-0.5 xl:rounded-lg">
        {title && (
          <h3 className="h3 text-red-900 pt-12">
            {title}
            <span className="text-red">.</span>
          </h3>
        )}
        {subtitle && (
          <h4 className="text-2xl font-display text-1-dark pb-12">
            {subtitle}
          </h4>
        )}
      </div>
    </div>
  );
}

export default HeadingTop;
