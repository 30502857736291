import React from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";
import { graphql } from "gatsby";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";
import Article from "../components/Article";

function Artikel({ data, location }) {
  const { state } = location;
  const person = state?.person;
  const event = state?.event;

  const eventFilter = (item, event) =>
    item.events?.map((e) => e.title).includes(event);

  const personFilter = (item, person) =>
    item.persons?.map((p) => p.title.title).includes(person);

  const events = data.events.nodes
    .map((item) => item.title)
    .sort((a, b) => (b === "Geburtstag") - (a === "Geburtstag"))
    .sort((a, b) => (b === event) - (a === event))
    .slice(0, 4);

  const persons = data.persons.nodes
    .map((item) => item.title.title)
    .sort((a, b) => (b === person) - (a === person))
    .slice(0, 4);

  return (
    <Layout>
      <SiteMetadata
        title="Handverlesene Artikel voller Geschenkideen!"
        description="Lass dich inspirieren von vielen tollen Geschenkideen für Mama, Papa, Oma und viel mehr!"
      />

      <HeadingTop
        title={"Handverlesene Artikel voller Ideen"}
        subtitle="Lass Dich inspirieren!"
      />
      <div className="container-xl">
        {person &&
          persons.map((person, i) => (
            <div key={i}>
              {i === 1 && (
                <div className="text-3xl gray-600 mt-8 font-display">
                  Weitere Artikel, die Dich inspieren könnten
                </div>
              )}
              <ArticleList
                person={person}
                items={data.articles.nodes.filter((item) =>
                  personFilter(item, person)
                )}
                title={`Artikel für ${person}`}
              />
            </div>
          ))}
        {event &&
          events.map((event, i) => (
            <div key={i}>
              {i === 1 && (
                <div className="text-3xl gray-600 mt-8 font-display">
                  Weitere Artikel, die Dich inspieren könnten
                </div>
              )}
              <ArticleList
                event={event}
                items={data.articles.nodes.filter((item) =>
                  eventFilter(item, event)
                )}
                title={`Artikel ${
                  event.endsWith("tag") ? "zum" : "für"
                } ${event}`}
              />
            </div>
          ))}
      </div>
    </Layout>
  );
}

const ArticleList = ({ items, title }) => (
  <div className="mt-8">
    <h3 className="text-2xl font-display text-gray-600">{title}</h3>
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-4">
      {items.map((item, i) => (
        <div key={i} className="w-full my-8">
          <Article data={item} type={"artikel"} />
        </div>
      ))}
    </div>
  </div>
);

export default Artikel;

export const query = graphql`
  query artikelPageQuery {
    articles: allContentfulArticle {
      nodes {
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        persons {
          title {
            title
          }
        }
        events {
          title
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM")
        title
      }
    }
    persons: allContentfulPerson {
      nodes {
        title: childContentfulPersonTitleTextNode {
          title
        }
      }
    }
  }
`;
