import React from "react";
import { BiChevronRight } from "react-icons/all";
import Card from "./BuildingBlocks/Card";

export default function Article({ data, type }) {
  const title = data?.title;
  const image = data?.image.localFile.childImageSharp.gatsbyImageData;
  return (
    <div className="font-display w-full">
      <Card title={title} image={image} type={type} large={true}>
        <div className="text-sm text-white bg-1 inline-block py-1.5 px-2 rounded-full hover:translate-x-1.5 transform transition">
          <span className="flex items-center">
            entdecken <BiChevronRight />
          </span>
        </div>
      </Card>
    </div>
  );
}
